@import '@aurora/shared-client/styles/_variables.pcss';
@import '@aurora/shared-client/styles/mixins/_focus.pcss';

.lia-nav {
  display: flex;
  align-items: center;
  background-color: var(--lia-local-bg-color, transparent);
  border-bottom: var(--lia-local-border-bottom, 1px solid var(--lia-bs-border-color));
}

.lia-container-bg {
  position: absolute;
  pointer-events: none;
  inset: 0;
}

.lia-crumb-list {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 1fr;
  column-gap: 5px;
  align-items: center;
  padding: 0;
  margin-bottom: 0;
}

.lia-crumb-list-item {
  display: flex;
  align-items: center;
  min-width: 0;
}

.lia-crumb-item {
  display: block;
  padding: 5px 0;
  margin: 10px 0;
  font-size: var(--lia-font-size-xs);
  font-weight: var(--lia-font-weight-md);
  line-height: var(--lia-bs-line-height-base);
  color: var(--lia-local-link-color, var(--lia-bs-text-muted));
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  a&:hover {
    color: var(--lia-local-link-hover-color, var(--lia-bs-text-muted));
  }
  span& {
    cursor: default;
  }
}

.lia-crumb-divider {
  display: flex;
}

.lia-crumb-icon {
  fill: var(--lia-local-link-color, var(--lia-local-icon-color));
  opacity: 0.5;
}

.lia-crumb-icon-mobile {
  fill: var(--lia-local-link-color, var(--lia-local-icon-color));
  margin-right: 5px;
  /* Unable to use flex for alignment because of truncation. */
  margin-top: -3px;
}

.lia-crumb-actions {
  display: flex;
  margin-left: auto;

  svg {
    fill: var(--lia-local-link-color);
  }
}

@media (--lia-breakpoint-up-lg) {
  .lia-crumb-icon-mobile {
    display: none;
  }
}

@media (--lia-breakpoint-down-md) {
  .lia-crumb-list-item:not(.lia-crumb-mobile),
  .lia-crumb-divider {
    display: none;
  }
}

.lia-dropdown-toggle {
  display: flex;
  padding: 8px 6px;

  .lia-ellipsis-icon {
    fill: var(--lia-local-link-color, var(--lia-local-icon-color));
  }
  &:hover .lia-ellipsis-icon {
    fill: var(--lia-local-link-hover-color, var(--lia-local-icon-color));
  }

  @include default-focus-outline();
}

.lia-editor-action-btn {
  top: 6px !important;
}

.lia-breadcrumb-edit-mode {
  pointer-events: none;
}
